import { Component, OnInit, HostListener, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlatformDetectorService } from './core/services/platform-detector.service';
import { SwUpdate } from '@angular/service-worker';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  displayWrapper() {
    let wrapper = document.getElementById('wrapper');
    wrapper.style.visibility = 'visible';
  }

  constructor(
    private _renderer: Renderer2,
    private swUpdate: SwUpdate,
    private _platformDetectorService: PlatformDetectorService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private _document
  ) {
    swUpdate.available.subscribe((event) => {
      if (this._platformDetectorService.isBrowser()) {
        window.location.reload();
      }
    });
  }

  ngOnInit() {
    this.displayWrapper();
    this.spinner.show();

    if (this._platformDetectorService.isBrowser()) {
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');

      this.subscriptions.push(
        this.onlineEvent.subscribe((e) => {
          this.connectionStatusMessage = 'Back to online';
          this.connectionStatus = 'online';
        })
      );

      this.subscriptions.push(
        this.offlineEvent.subscribe((e) => {
          this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
          this.connectionStatus = 'offline';
        })
      );
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }

  @HostListener('document:gesturestart', ['$event'])
  ongesturestart(event) {
    event.preventDefault();
    this._document.body.style.zoom = 1;
  }

  @HostListener('document:gesturechange', ['$event'])
  ongesturechange(event) {
    event.preventDefault();
    this._document.body.style.zoom = 1;
  }

  @HostListener('document:gestureend', ['$event'])
  ongestureend(event) {
    event.preventDefault();
    this._document.body.style.zoom = 1;
  }
}
