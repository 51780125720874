import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  dataLoaded: boolean = false;
  isSubscriptionOpen = false;
  storeName: string;
  urlSlug: string;

  constructor(private _route: ActivatedRoute, private _contentService: ContentService) {}

  ngOnInit() {
    this.urlSlug = this._route.snapshot.params.slug || null;
    this.loadContent(this.urlSlug);
  }

  loadContent(slug: string) {
    this._contentService
      .getOffers(slug)
      .pipe(
        catchError((val) => of(`Error: ${val}`)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res) => {
        if (res.success) {
          this.storeName = res.store_name;
        } else {
          this.storeName = 'Direkten';
        }
        this.dataLoaded = true;
      });
  }

  openSubscriptionModal() {
    this.isSubscriptionOpen = true;
  }
}
