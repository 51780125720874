import { Component, OnInit, OnDestroy } from '@angular/core';
import { Offer } from '../../models/offer';
import { Observable, Subject, Subscription, fromEvent, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { PlatformDetectorService } from '../../services/platform-detector.service';
import { catchError, takeUntil } from 'rxjs/operators';

import { SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { THUMBS_SLIDER_CONFIG } from './thumbsSliderConfig';

@Component({
  selector: 'app-thumbs-slider',
  templateUrl: './thumbs-slider.component.html',
  styleUrls: ['./thumbs-slider.component.scss'],
  providers: [{ provide: SWIPER_CONFIG, useValue: THUMBS_SLIDER_CONFIG }]
})
export class ThumbsSliderComponent implements OnInit, OnDestroy {
  THUMBS_SLIDER_CONFIG: SwiperConfigInterface;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;

  selectedOfferId: number | string;
  selectedOffer: any;
  offersArray: Offer[];

  urlSlug: string;
  pageReloadTimer: any;
  TIMER_DELAY: 21600000;
  networkConnectionStatus: string;

  isSubscriptionOpen = false;
  isOfferOpen = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _contentService: ContentService,
    private _platformDetectorService: PlatformDetectorService
  ) {}

  ngOnInit() {
    this.urlSlug = this._route.snapshot.params.slug || null;
    this.urlSlug ? this.loadContent(this.urlSlug) : this._router.navigate(['/not-found']);

    if (this._platformDetectorService.isBrowser()) {
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      this.subscriptions.push(
        this.onlineEvent.subscribe((e) => {
          this.connectionStatusMessage = 'Back to online';
          this.connectionStatus = 'online';

          this.networkConnectionStatus = this.checkNetworkConnectionStatus(this.connectionStatus, 'online');

          if (this.networkConnectionStatus === this.connectionStatus) {
            if (this._platformDetectorService.isBrowser()) {
              setTimeout(() => {
                if (!this.isOfferOpen && this.urlSlug !== null) {
                  window.location.reload(true);
                }
              }, 2000);
            }
            this.reloadPageAfterDelay(21600000);
          }
        })
      );

      this.subscriptions.push(
        this.offlineEvent.subscribe((e) => {
          this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
          this.connectionStatus = 'offline';

          this.networkConnectionStatus = this.checkNetworkConnectionStatus(this.connectionStatus, 'offline');

          if (this.networkConnectionStatus === this.connectionStatus) {
            clearInterval(this.pageReloadTimer);
          }
        })
      );
    }
    this.reloadPageAfterDelay(21600000);
  }

  loadContent(slug: string) {
    this._contentService
      .getOffers(slug)
      .pipe(
        catchError((val) => of(`Error: ${val}`)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((res) => {
        if (res.success) {
          this.offersArray = res.data.sort(function(a, b) {
            return a.priority - b.priority;
          });
        } else {
          this._router.navigate(['']);
        }
      });
  }

  openSubscriptionModal() {
    this.isSubscriptionOpen = true;
  }

  openOfferModal(offer: Offer) {
    this.selectedOfferId = offer.id;
    this.selectedOffer = offer;
    this.isOfferOpen = true;
  }

  reloadPageAfterDelay(delay: number) {
    if (this._platformDetectorService.isBrowser()) {
      this.pageReloadTimer = setInterval(() => {
        if (!this.isOfferOpen && this.urlSlug !== null) {
          window.location.reload(true);
        }
      }, delay);
    }
  }

  checkNetworkConnectionStatus(status: string, logMsg: string) {
    console.log(logMsg);
    if (this.connectionStatus !== undefined && this.connectionStatus === status) {
      return status;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    clearInterval(this.pageReloadTimer);
  }
}
