import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const HERO_SLIDER_CONFIG: SwiperConfigInterface = {
  init: true,
  initialSlide: 1,
  slidesPerView: 1,
  direction: 'horizontal',
  speed: 1000,
  observer: true,
  observeParents: true,
  observeSlideChildren: true,
  grabCursor: true,
  simulateTouch: true,
  loop: true,
  autoplay: {
    delay: 10000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: true
  },
  pagination: true
};
