import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class PlatformDetectorService {

  private isPlatformBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isPlatformBrowser = isPlatformBrowser(platformId);
  }

  public isBrowser(): boolean {
    return this.isPlatformBrowser;
  }

}
