import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ContentService } from '../../../services/content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValidationService } from '../../../modules/validation/services/validation.service';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss']
})
export class OfferModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Output() closeDialog = new EventEmitter<any>();
  @Input() offerId;
  @Input() offer;

  isSuccessfullySent = false;

  isTermsModalOpen = false;
  isContentHidden = false;

  openedPolicyType: string;

  isAgreeWithGDPR = false;
  serverErrors = {};
  offerForm: FormGroup;
  offerValidation: any = {
    phone: ['required']
  };

  closeAnimation = false;

  constructor(private _fb: FormBuilder, private _contentService: ContentService, private _validationService: ValidationService) {}

  ngOnInit() {
    this.offerForm = this._fb.group(this._validationService.getFormGroupObject({ phone: '' }));
    this.listenToSearchInputChanges();
  }

  listenToSearchInputChanges() {
    let prevValues = this.offerForm.controls;
    this.offerForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (!!data['phone'] && data['phone'].toString().length > 13) {
        this.offerForm.controls['phone'].patchValue(prevValues['phone'], { emitEvent: false });
      }
      prevValues = this.offerForm.value;
    });
  }

  onSendForm() {
    if (this.isAgreeWithGDPR) {
      this._contentService
        .sendPhone({ phone: this.offerForm.get('phone').value.replace(/ /g, ''), offer_id: this.offerId })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res.ok === false) {
            this.serverErrors = res.error.error_data.errors;
          } else {
            this.serverErrors = '';
            this.isSuccessfullySent = true;
          }
        });
    }
  }

  onClose() {
    this.closeAnimation = true;
    setTimeout(() => {
      this.closeDialog.emit();
    }, 800);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
