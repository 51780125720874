import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(control: AbstractControl): { [key: string]: any } {

  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
  const value = control.value;

  const result = emailRegex.test(value);

  if (result || !value || value.length === 0) {
    return null;
  } else {
    return { 'emailValidator': { value } };
  }
}

export function maxValue(max: Number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value,
      isValid = input > max;
    if (isValid) {
      return { 'maxValue': { max } };
    } else {
      return null;
    }
  };
}

export function equalTo(name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const inputVal = control.value,
      patternControlVal = control.parent.controls[name].value,
      notValid = (inputVal !== patternControlVal) && inputVal && patternControlVal;
    if (notValid) {
      return { 'equalTo': { name } };
    } else {
      return null;
    }
  };
}
export function patterForEqual(name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    control.parent.controls[name].updateValueAndValidity();
    return null;
  };
}
export function minValue(min: Number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const input = control.value,
      isValid = input < min;
    if (isValid) {
      return { 'maxValue': { min } };
    } else {
      return null;
    }
  };
}

export function urlValidator(control: AbstractControl): { [key: string]: any } {
  const urlRegex = /^((http[s]?|ftp):\/)?\/?([^:\/\s]+)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
  const value = control.value;
  const result = urlRegex.test(value);

  if (result || value.length === 0) {
    return null;
  } else {
    return {'urlValidator': { value }};
  }
}

export function dateTimeValidator(control: AbstractControl): { [key: string]: any } {
  const value = control.value;
  const matches = String(value).match(/^(\d{2})-(\d{2})-(\d{4}), (\d{2}):(\d{2}) (\S{2})$/);
  if (matches === null) {
    return {'dateTimeValidator': { value }};
  } else {
    const year = parseInt(matches[3], 10);
    const month = parseInt(matches[2], 10) - 1; // months are 0-11
    const day = parseInt(matches[1], 10);
    const hour = parseInt(matches[4], 10);
    const minute = parseInt(matches[5], 10);
    const meridian = matches[6];
    const date = new Date(year, month, day, hour, minute, 0);
    if (
      date.getFullYear() !== year
      || date.getMonth() !== month
      || date.getDate() !== day
      || date.getHours() !== hour
      || hour > 12
      || date.getMinutes() !== minute
      || (meridian !== 'AM' && meridian !== 'PM')
    ) {
      return {'dateTimeValidator': { value }};
    } else {
      return null;
    }
  }
}
