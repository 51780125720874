import { Injectable } from '@angular/core';
import { PlatformDetectorService } from './platform-detector.service';

@Injectable()
export class DeviceService {

  private isMobileDevice = false;

  constructor(private platformDetectorService: PlatformDetectorService) {
    this.isMobileDevice = (this.platformDetectorService.isBrowser()) ? /Android|iPhone|iPad/i.test(window.navigator.userAgent) : false;
  }

  public isMobile(): boolean {
    return this.isMobileDevice;
  }
}
