import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { TermsModalComponent } from './components/modals/terms-modal/terms-modal.component';
import { OfferModalComponent } from './components/modals/offer-modal/offer-modal.component';
import { SubscriptionModalComponent } from './components/modals/subscription-modal/subscription-modal.component';
import { SharedModule } from '../shared/shared.module';
import { ValidationService } from './modules/validation/services/validation.service';
import { PlatformDetectorService } from './services/platform-detector.service';
import { DeviceService } from './services/device.service';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { HeroSliderComponent } from './components/hero-slider/hero-slider.component';
import { ThumbsSliderComponent } from './components/thumbs-slider/thumbs-slider.component';
// import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SharedModule, SwiperModule, NgxSpinnerModule],
  declarations: [
    HeaderComponent,
    TermsModalComponent,
    OfferModalComponent,
    SubscriptionModalComponent,
    HeroSliderComponent,
    ThumbsSliderComponent
  ],
  providers: [ValidationService, PlatformDetectorService, DeviceService],
  exports: [
    HeaderComponent,
    TermsModalComponent,
    OfferModalComponent,
    SubscriptionModalComponent,
    HeroSliderComponent,
    ThumbsSliderComponent,
    NgxSpinnerModule
  ]
})
export class CoreModule {}
