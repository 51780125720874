import {Injectable} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ValidatorFn} from '@angular/forms';

@Injectable()
export class ValidationService {

  constructor() {}

  getFormGroupObject(modelObj: any) {
    const formGroupObj = {};
    Object
      .keys(modelObj)
      .forEach((name) => {
        formGroupObj[name] = [modelObj[name]];
      });
    return formGroupObj;
  }

  validateModel(modelData: Object, validationRules: Object): boolean {
    let modelIsValid = true;
    Object
      .keys(validationRules)
      .forEach(key => {
        const formControl = new FormControl();
        formControl.setValidators(this.getValidatorsArrayByName(validationRules, key));
        formControl.setValue(modelData[key]);
        modelIsValid = modelIsValid && formControl.valid;
      });
    return modelIsValid;
  }

  getValidatorByRule(rule) {
    let validatorRule = rule;
    if (typeof rule === 'string') {
      // Validators.required
      validatorRule = Validators[rule];
    } else if (rule instanceof Array) {
      // Validators.minLength(6)
      validatorRule = Validators[rule[0]](rule[1]);
    }
    return validatorRule;
  }

  getValidatorsArrayByName(rules: Object, name: string): ValidatorFn[] {
    const validatorsArray: ValidatorFn[] = [];
    for (const rule of rules[name]) {
      validatorsArray.push(this.getValidatorByRule(rule));
    }
    return validatorsArray;
  }

}
