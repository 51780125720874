export function detectSlideAfterInit() {
  this.detectPrevSlideAfterInit();
  this.detectActiveSlideAfterInit();
  this.detectNextSlideAfterInit();
}

export function detectPrevSlideAfterInit() {
  let prevSlide = document.querySelector('.swiper-slide-prev .wrapper-content');
  prevSlide.classList.add('hidden');
}

export function detectActiveSlideAfterInit() {
  let activeSlide = document.querySelector('.swiper-slide-active .wrapper-content');
  activeSlide.classList.remove('hidden');
}

export function detectNextSlideAfterInit() {
  let nextSlide = document.querySelector('.swiper-slide-next .wrapper-content');
  nextSlide.classList.add('hidden');
}
