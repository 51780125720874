import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(@Inject(PLATFORM_ID) private platformId, private apiService: ApiService) {}

  public getHeroOffers(slug: string) {
    return this.apiService.get(`offers/hero-slider`, { slug });
  }

  public getOffers(slug: string) {
    return this.apiService.get(`offers`, { slug });
  }

  public sendPhone(params: {}) {
    return this.apiService.post(`send/sms`, params);
  }

  public subscribeCustomer(params: { phone: string; email: string; store: string }) {
    return this.apiService.post(`${params.store}/subscribe/customer`, params);
  }
}
