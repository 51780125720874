import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent implements OnInit {
  @Output() closeDialog = new EventEmitter<any>();
  @Input() policyType: string;

  isCookiePolicyOpen = false;

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      this.onClose();
    }
  }

  openCookiePolicy() {
    this.isCookiePolicyOpen = true;
  }

  onClose() {
    this.isCookiePolicyOpen ? this.isCookiePolicyOpen = false : this.closeDialog.emit();
  }


}
