import { Component, OnInit, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ValidationService } from '../../../core/modules/validation/services/validation.service';

class ValidationMessages {
    required = 'Telefonnumret krävs';
    minlength = 'Minimum %d characters';
    maxlength = 'Maximum %d characters';
    emailValidator = 'E-postadressen är inte korrekt';
    dateTimeValidator  = 'Not valid date';
    equalTo = 'The password confirmation and password must match';
    patterForEqual = '---';
    pattern = 'This field format is invalid';
    urlValidator = 'The url format is invalid';

    constructor(data?: any) {
        if (data) {
            Object.keys(data).forEach((name) => {
                this[name] = data[name];
            });
        }
    }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'validation-wrapper',
  templateUrl: './validation-wrapper.component.html',
  styleUrls: ['./validation-wrapper.component.scss']
})
export class ValidationWrapperComponent implements OnInit, OnDestroy, AfterContentInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  messages: ValidationMessages;
  formInputControl: any;
  @Input() vFormGroup: FormGroup;
  @Input() fieldName: string;
  @Input() vRules: any;
  @Input() vMessages: any;
  @Input() errorMessages: any = [];
  @Input() errorStyle: any;

  constructor(private validationService: ValidationService) { }

  ngOnInit() {}

  ngAfterContentInit() {
    this.formInputControl = this.vFormGroup.get(this.fieldName);
    this.messages = this.updatedMessagesWithRuleValues(new ValidationMessages(this.vMessages));
    this.formInputControl.setValidators(this.validationService.getValidatorsArrayByName(this.vRules, this.fieldName));
    this.formInputControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (this.errorMessages[this.fieldName]) {
          delete this.errorMessages[this.fieldName];
        }
      });
  }

  messagesKeys(): Array<string> {
    return Object.keys(this.messages);
  }

  updatedMessagesWithRuleValues(messages: ValidationMessages) {
    for (const rule of this.vRules[this.fieldName]) {
      if (rule instanceof Array) {
        messages[rule[0].toLowerCase()] = messages[rule[0].toLowerCase()].replace('%d', rule[1]);
      }
    }
    return messages;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

