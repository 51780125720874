import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContentService } from '../../../services/content.service';
import { ValidationService } from '../../../modules/validation/services/validation.service';
import { Subject } from 'rxjs';
import { emailValidator } from '../../../modules/validation/validators/custom-validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @Output() closeDialog = new EventEmitter<any>();

  isSuccessfullySent = false;

  isTermsModalOpen = false;
  isContentHidden = false;

  openedPolicyType: string;

  isAgreeWithGDPR = false;
  serverErrors = {};
  subscriptionForm: FormGroup;
  subscriptionValidation: any = {
    phone: [],
    email: [emailValidator]
  };

  constructor(
    private _fb: FormBuilder,
    private _contentService: ContentService,
    private _validationService: ValidationService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.subscriptionForm = this._fb.group(this._validationService.getFormGroupObject({ phone: '', email: '' }));
    this.listenToSearchInputChanges();
  }

  listenToSearchInputChanges() {
    let prevValues = this.subscriptionForm.controls;
    this.subscriptionForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (!!data['phone'] && data['phone'].toString().length > 13) {
        this.subscriptionForm.controls['phone'].patchValue(prevValues['phone'], { emitEvent: false });
      }
      prevValues = this.subscriptionForm.value;
    });
  }

  onSendForm() {
    if (this.isAgreeWithGDPR) {
      this._contentService
        .subscribeCustomer({
          phone: this.subscriptionForm.get('phone').value.replace(/ /g, '') || null,
          email: this.subscriptionForm.get('email').value || null,
          store: this._router.url.replace('/', '')
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res.ok === false) {
            this.serverErrors = res.error.error_data.errors;
          } else {
            this.serverErrors = '';
            this.isSuccessfullySent = true;
          }
        });
    }
  }

  onClose() {
    this.closeDialog.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
