import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const THUMBS_SLIDER_CONFIG: SwiperConfigInterface = {
  init: true,
  observer: true,
  direction: 'horizontal',
  spaceBetween: 15,
  slidesPerView: 4,
  grabCursor: true,
  simulateTouch: true
};
