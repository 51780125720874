export function detectSlideOnInit() {
  this.detectPrevSlideOnInit();
  this.detectActiveSlideOnInit();
  this.detectNextSlideOnInit();
}

export function detectPrevSlideOnInit() {
  let prevSlide = document.querySelector('.swiper-slide-prev .wrapper-content');
  prevSlide.classList.remove('hidden');
}

export function detectActiveSlideOnInit() {
  let activeSlide = document.querySelector('.swiper-slide-active .wrapper-content');
  activeSlide.classList.add('hidden');
}

export function detectNextSlideOnInit() {
  let nextSlide = document.querySelector('.swiper-slide-next .wrapper-content');
  nextSlide.classList.add('hidden');
}
