export function animateSlide(pause: number) {
  this.animateLogo(pause);
  this.animateTitle(pause);
  this.animateDescription(pause);
  this.animateButton(pause);
  this.animateTerms(pause);
}

export function animateLogo(pause: number) {
  const logos = Array.from(document.querySelectorAll('#hero_slider_logo'));
  const timer = setTimeout(() => this.animateElement(logos, effect), pause);
  const effect = 'bounce-in-fwd';

  this.clearElement(logos, effect);
  setTimeout(() => this.animateElement(logos, effect), pause);
  clearTimeout(timer);
  this.clearElement(logos, effect);
}

export function animateTitle(pause: number) {
  const titles = Array.from(document.querySelectorAll('#hero_slider_title'));
  const timer = setTimeout(() => this.animateElement(titles, effect), pause);
  const effect = 'slide-in-left';

  this.clearElement(titles, effect);
  setTimeout(() => this.animateElement(titles, effect), pause);
  clearTimeout(timer);
  this.clearElement(titles, effect);
}

export function animateDescription(pause: number) {
  const descriptions = Array.from(document.querySelectorAll('#hero_slider_description'));
  const timer = setTimeout(() => this.animateElement(descriptions, effect), pause);
  const effect = 'slide-in-right';

  this.clearElement(descriptions, effect);
  setTimeout(() => this.animateElement(descriptions, effect), pause);
  clearTimeout(timer);
  this.clearElement(descriptions, effect);
}

export function animateButton(pause: number) {
  const buttons = Array.from(document.querySelectorAll('#hero_slider_button'));
  const timer = setTimeout(() => this.animateElement(buttons, effect), pause);
  const effect = 'fade-in';

  this.clearElement(buttons, effect);
  setTimeout(() => this.animateElement(buttons, effect), pause);
  clearTimeout(timer);
  this.clearElement(buttons, effect);
}

export function animateTerms(pause: number) {
  const buttons = Array.from(document.querySelectorAll('#hero_slider_terms'));
  const timer = setTimeout(() => this.animateElement(buttons, effect), pause);
  const effect = 'fade-in';

  this.clearElement(buttons, effect);
  setTimeout(() => this.animateElement(buttons, effect), pause);
  clearTimeout(timer);
  this.clearElement(buttons, effect);
}

export function clearElement(elements: any[], effect: string) {
  elements.forEach((element) => {
    element.classList.add('hidden');
    element.classList.remove(effect);
  });
}

export function animateElement(elements: any[], effect: string) {
  elements.forEach((element) => {
    element.classList.remove('hidden');
    element.classList.add(effect);
  });
}
