import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, throwError } from 'rxjs';
import {share, catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

interface ApiResponse {
  error: boolean;
  data: any;
  message: string;
  errors: any;
  popup: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) {
  }

  public get(path: string, params?: any): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.api_url}${path}`, {params: params})
      .pipe(
        share(),
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public getStatic(file: string, params?: any): Observable<any> {
    return this.http
      .get<any>(`${environment.static_path}${file}`).pipe(
        share(),
        catchError((error: any) => {
          return this.handleError(error);
        })
      );
  }

  public post(path: string, params: any): Observable<any> {
    return this.http.post(`${environment.api_url}${path}`, params).pipe(
      share(),
      catchError(error => of(error))
    );
  }

  public put(path: string, params: any): Observable<any> {
    return this.http.put(`${environment.api_url}${path}`, params).pipe(
      share(),
      catchError(error => of(error))
    );
  }

  public delete(path: string): Observable<any> {
    return this.http.delete(`${environment.api_url}${path}`).pipe(
      share(),
      catchError(error => of(error))
    );
  }

  private handleError(error: any) {
    return throwError(error.status);
  }
}
